<template>
    <v-app-bar dark height="80">
        <div class="w-full d-flex align-center justify-space-between">
            <div class="d-flex align-center">
                <v-icon :disabled="!validConfig" large class="mr-6 pa-2 highlight" @click="getRouter.push('/')">
                    mdi-arrow-left-bold
                </v-icon>
                <v-toolbar-title class="text-h4">Configuration</v-toolbar-title>
            </div>
        </div>
    </v-app-bar>
</template>

<script setup>

import router from '@/router';
import { computed } from 'vue';

import { useAppStore } from '@/store/app';
import { storeToRefs } from 'pinia';

const { validConfig } = storeToRefs(useAppStore());

const getRouter = computed(() => {
    return router;
});


</script>
