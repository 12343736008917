//COMMENT add comments
<template>
    <v-navigation-drawer
        v-model="drawerHack"
        temporary
        app
    >
        <v-list nav>
            <v-list-item  to="settings">
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Configuration</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <div @click="reload" class="py-4 w-full text-center grey clickable lighten-4 font-weight-black">
                {{ version }}
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>

import { useAppStore } from '@/store/app.js';

import { mapWritableState } from 'pinia';
import pkg from '../../../package.json';

import { getSWRegistration } from '@/registerServiceWorker';

export default {
    data(){
        return {
            drawerHack: false
        };
    },
    watch:{
        drawer(){
            if (this.drawer) {
                this.drawerHack = this.drawer;
            }
        },
        drawerHack(){
            if(!this.drawerHack){
                this.drawer = false;
            }
        }
    },
    computed:{
        ...mapWritableState(useAppStore, ['drawer']),
        version () {
            return pkg.version;
        }
    },
    methods:{
        async reload () {
            const registration = getSWRegistration();
            if (navigator.onLine && registration) {
                registration.unregister().then(async () => {
                    if ('caches' in window) {
                        let keys = await caches.keys();
                        for (let i = 0; i < keys.length; i++) {
                            await caches.delete(keys[i]);
                        }
                    }

                    location.reload(true);
                });
            } else if (navigator.onLine) {
                if ('caches' in window) {
                    let keys = await caches.keys();
                    for (let i = 0; i < keys.length; i++) {
                        await caches.delete(keys[i]);
                    }
                }

                location.reload(true);
            } else {
                useAppStore().showSnackbar('L\'application n\'a pas de réseau internet...');
            }
        }
    },
    beforeDestroy(){
        this.drawer = false;
    }

};
</script>
