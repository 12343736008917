//COMMENT add comments
<template>
    <v-app-bar dark height="80" class="w-full">
        <div class="d-flex justify-space-between w-full align-center">
            <div class="d-flex w-full align-center">
                <v-app-bar-nav-icon large @click="toggleDrawer()" />
                <v-toolbar-title class="render-limit text-h4">Prestations</v-toolbar-title>
            </div>
            <div>
                <v-icon class="mr-4" large @click="uploadPrestation">
                    mdi-cloud-upload
                </v-icon>
            </div>

        </div>
    </v-app-bar>
</template>

<script setup>

import { useAppStore } from '@/store/app.js';
import { usePrestationStore } from '@/store/prestation';

const { toggleDrawer } = useAppStore();
const { uploadPrestation } = usePrestationStore();

</script>
