//COMMENT add comments
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import _debounce from 'lodash/debounce';


export const useAppStore = defineStore('app', () => {
    const defaultSettings = {
        apiUsername: '',
        apiPassword: '',
        apiUrl: '',
        apiDeviceKey: uuidv4(),
        pincode: '',
        gpstrack: true,
        barcodeStop: false,
        autoUpload: true,
    };

    const settings = ref(JSON.parse(localStorage.getItem('settings')) ?? defaultSettings);

    const drawer = ref(false);
    const gps = ref(null);
    const coordinats = ref({
        lat: null,
        long: null
    });
    const snackbar = ref({
        show: false,
        color: "black",
        text: ''
    });

    const validConfig = computed(() => {
        return settings.value.apiUsername && settings.value.apiPassword && settings.value.apiUrl;
    });

    watch(settings, _debounce(() => {
        setupAxios();
        localStorage.setItem('settings', JSON.stringify(settings.value));
    }, 300), {deep: true});

    function setupAxios () {
        axios.defaults.baseURL = settings.value.apiUrl;
        axios.defaults.headers.common['Wisol-Api-App-Key']    = process.env.VUE_APP_API_KEY;
        axios.defaults.headers.common['Wisol-Api-Device-Key'] = settings.value.apiDeviceKey;
        axios.defaults.auth = {
            username: settings.value.apiUsername,
            password: settings.value.apiPassword
        };
    }

    function toggleDrawer () {
        drawer.value = !drawer.value;
    }

    function openMap (mapData) {
        const here = coordinats.value.lat + ',' + coordinats.value.long;
        if ((navigator.platform.indexOf('iPhone') !== -1) || (navigator.platform.indexOf('iPad') !== -1) || (navigator.platform.indexOf('iPod') !== -1)) {
            window.open('http://maps.apple.com/maps?q=' + here);
        } else {
            window.open('https://www.google.com/maps/dir/' + here + '/' + mapData.GPS);
        }
    }

    function loadGps () {
        if (settings.value.gpstrack === true) {
            let options = {
                maximumAge: 30000
            };
            gps.value = navigator.geolocation.watchPosition(
                (position) => {
                    coordinats.value.lat = position.coords.latitude;
                    coordinats.value.long = position.coords.longitude;
                },
                () => {
                    settings.value.gpstrack = false;
                }, options);
        } else {
            navigator.geolocation.clearWatch(gps.value);
        }
    }

    function showSnackbar (text, color='black') {
        let timeout = 0;
        if(this.snackbar.show){
            this.snackbar.show = false;
            timeout = 300;
        }
        setTimeout(() => {
            this.snackbar.text = text;
            this.snackbar.color = color;
            this.snackbar.show = true;
        }, timeout);
    }

    setupAxios();

    return {
        settings,
        drawer,
        gps,
        coordinats,
        toggleDrawer,
        openMap,
        loadGps,
        showSnackbar,
        snackbar,
        validConfig
    };
});
