<template>
    <v-main>
        <portal to="navigation">
            <app-drawer />
        </portal>

        <portal to="header">
            <HomeHeader />
        </portal>

        <HomeBody />

        <portal to="footer">
            <HomeFooter />
        </portal>
    </v-main>
</template>

<script setup>

import HomeHeader from '@/components/Home/HomeHeader.vue';
import HomeBody from '@/components/Home/HomeBody.vue';
import HomeFooter from '@/components/Home/HomeFooter.vue';

import { useAppStore } from '@/store/app';

useAppStore().loadGps();
</script>
