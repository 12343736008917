import Vue from 'vue';
import App from './App.vue';

import './registerServiceWorker';

import router from './router';
import vuetify from './plugins/vuetify';

// import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

import PortalVue from 'portal-vue';

import { PiniaVuePlugin } from 'pinia';
import { pinia } from '@/store';

import AppBtn from '@/components/Common/AppBtn.vue';
import AppDrawer from '@/components/Common/DrawerComponent.vue';

Vue.use(PiniaVuePlugin);
Vue.use(PortalVue);

Vue.config.productionTip = false;

Vue.component('app-btn', AppBtn);
Vue.component('app-drawer', AppDrawer);

new Vue({
    router,
    vuetify,
    pinia,
    render: function (h) { return h(App); }
}).$mount('#app');
