<template>
    <div @click="$emit('close')" class="overlay">
        <div @click.stop style="background-color: #fff; padding: 20px; border-radius: 10px; border: #0008 solid 1px;">
            <qrcode-stream
                @decode="onDecode"
                @init="onInit"
            ></qrcode-stream>
        </div>
    </div>
</template>


<script>

import { QrcodeStream } from 'vue-qrcode-reader';

export default {
    components: {
        QrcodeStream
    },

    methods: {
        onDecode (evt) {
            this.$emit('scan', evt);
        },
        async onInit (promise) {
            try {
                await promise;
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    error.value = 'ERROR: you need to grant camera access permission';
                } else if (error.name === 'NotFoundError') {
                    error.value = 'ERROR: no camera on this device';
                } else if (error.name === 'NotSupportedError') {
                    error.value = 'ERROR: secure context required (HTTPS, localhost)';
                } else if (error.name === 'NotReadableError') {
                    error.value = 'ERROR: is the camera already in use?';
                } else if (error.name === 'OverconstrainedError') {
                    error.value = 'ERROR: installed cameras are not suitable';
                } else if (error.name === 'StreamApiNotSupportedError') {
                    error.value = 'ERROR: Stream API is not supported in this browser';
                } else if (error.name === 'InsecureContextError') {
                    error.value =
                        'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
                } else {
                    error.value = `ERROR: Camera error (${error.name})`;
                }
            }
        }
    }

};
</script>

