//COMMENT add comments
<template>
    <v-tooltip :disabled="hasToolTip" :bottom="bottom" :left="left" :right="right" :top="top">
        <template v-slot:activator="{ on, attrs }">
            <v-btn depressed :to="to" v-on="on" :fab="fab" :text="text" :large="large" :right="absRight" :small="small"
                   :color="color" v-bind="attrs" :class="btnClass" :disabled="disabled" :absolute="absolute" @click="onClick">
                <slot />
            </v-btn>
        </template>
        <span>{{ tip }}</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        large: { type: Boolean },

        to: { type: String, },
        tip: { type: String },
        btnClass: { default: '' },

        fab: { type: Boolean },
        text: { type: Boolean },
        small: { type: Boolean },
        disabled: { type: Boolean },

        absolute: { type: Boolean, default: false },
        'abs-right': { type: Boolean },

        top: { type: Boolean },
        left: { type: Boolean },
        right: { type: Boolean },
        bottom: { type: Boolean },
    },
    methods: {
        onClick () {
            window.navigator.vibrate(50);
            this.$emit('click');
        }
    },
    computed: {
        hasToolTip () {
            return !this.tip;
        }
    }
};
</script>
