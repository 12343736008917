/* eslint-disable no-console */

import { register } from 'register-service-worker';

let registration = null;

export function getSWRegistration () {
    return registration;
}


if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready (r) {
            registration = r;
            console.log(
                'App is being served from cache by a service worker.\n' +
                'For more details, visit https://goo.gl/AFskqB'
            );
        },
        cached (r) {
            registration = r;
            console.log('Content has been cached for offline use.');
        },
        updated (r) {
            registration = r;
            console.log('New content is available; please refresh.');
        },
        offline () {
            console.log('No internet connection found. App is running in offline mode.');
        },
        error (error) {
            console.error('Error during service worker registration:', error);
        }
    });
}
