<template>
    <v-footer class="bt-footer" padless>
        <div class="w-full my-4 d-flex align-center justify-center">
            <app-btn @click="qrscanner = true" fab large>
                <v-icon @click="qrscanner = true" large>
                    mdi-camera
                </v-icon>
            </app-btn>
            <QRScanner @scan="onScan" @close="qrscanner = false" v-if="qrscanner"/>
        </div>
    </v-footer>
</template>

<script setup>

import QRScanner from '../Common/QRScanner.vue';

import { useAppStore } from '@/store/app';
import { usePrestationStore } from '@/store/prestation';
import { ref } from 'vue';

const qrscanner = ref(false);

const { handleTask } = usePrestationStore();
const { openMap } = useAppStore();

function onScan(qr) {
    qrscanner.value = false;
    if (qr.includes('task:')) {
        let task = qr.split('task:');
        task = JSON.parse(task[1]);
        handleTask(task);
    } else if (qr.includes('maps:')) {
        let maps = qr.split('maps:');
        maps = JSON.parse(maps[1]);
        openMap(maps);
    }
}
</script>
