//COMMENT add comments
<template>
    <v-snackbar v-if="snackbar.text" v-model="snackbar.show" class="mt-16" :content-class="snackbar.color" top>
        {{ snackbar.text }}

        <template v-slot:action="{ attrs }">
            <button v-bind="attrs" style="padding-left: 8px;" @click="snackbar.show = false">
                Fermer
            </button>
        </template>
    </v-snackbar>
</template>

<script setup>

import { useAppStore } from '@/store/app';
import { storeToRefs } from 'pinia';

const { snackbar } = storeToRefs(useAppStore());

</script>
