
import format from 'date-fns/format';

function getDateStamp (date) {
    return format(date, 'Y-MM-dd');
}

function getTimeStamp (date) {
    return format(date, 'HH:mm');
}

export { getDateStamp, getTimeStamp };
