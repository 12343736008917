import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VNavigationDrawer,{attrs:{"temporary":"","app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"py-4 w-full text-center grey clickable lighten-4 font-weight-black",on:{"click":_vm.reload}},[_vm._v(" "+_vm._s(_vm.version)+" ")])]},proxy:true}]),model:{value:(_vm.drawerHack),callback:function ($$v) {_vm.drawerHack=$$v},expression:"drawerHack"}},[_c(VList,{attrs:{"nav":""}},[_c(VListItem,{attrs:{"to":"settings"}},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-cog")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Configuration")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }