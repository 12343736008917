<template>
    <div style="height: 100%;" class="d-flex flex-column">
        <div style="border-bottom: 1px solid #000; background-color: #fff;" class="pa-4 d-flex align-center justify-space-around">
            <v-icon large @click="--days">
                mdi-arrow-left-bold-circle
            </v-icon>
            <span class="clickable" style="font-weight: 700;" @click="days = 0"> {{ displayDate }}</span>
            <v-icon large @click="++days">
                mdi-arrow-right-bold-circle
            </v-icon>
        </div>
        <div style="height: 100%; overflow: auto;" v-if="prestOfDay">
            <div
                v-for="prestation in prestOfDay"
                :key="prestation.id"

                :class="{'pa-6': true, 'locked': (prestation.send || prestation.locked)}"
                style="background-color: #fff; border-bottom: 1px solid #000;"
            >
                <div class="d-flex align-center justify-space-between">
                    <div class="d-flex flex-column mr-6">
                        <span style="font-size: 120%;" :class="{'success--text': !prestation.heure_fin}">{{ prestation.heure_debut }}</span>
                        <span style="font-size: 120%; text-align: center;">-</span>
                        <span style="font-size: 120%;">{{ prestation.heure_fin }}</span>
                    </div>
                    <v-text-field
                        v-model="prestation.designation"
                        :disabled="prestation.send || prestation.locked"

                        style="width: 200px;"
                        outlined
                        hide-details
                    />
                    <v-btn fab depressed @click="closePrest(prestation)" v-if="!prestation.heure_fin && settings.barcodeStop" color="white" class="ml-4">
                        <v-icon color="red">
                            mdi-square
                        </v-icon>
                    </v-btn>
                </div>
            </div>
        </div>
        <div v-else>
            <i class="d-flex justify-center mt-6">
                <h3>Aucune Prestation pour ce jour</h3>
            </i>
        </div>
    </div>
</template>

<script setup>

import { getDateStamp } from '@/helper/date';
import format from 'date-fns/format';
import add from 'date-fns/add';
import { usePrestationStore } from '@/store/prestation';
import { computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';
import locFr from 'date-fns/locale/fr';

const cDate = ref(new Date());
const days = ref(0);

const { prestations } = storeToRefs(usePrestationStore());
const { closePrest } = usePrestationStore();
const { settings } = storeToRefs(useAppStore());

watch(days, (val) => {
    const abs = Math.abs(val);
    if (abs <= 7) {
        days.value = val;
    } else {
        days.value = val < 0 ? -7 : 7;
    }
});

const calcDate = computed(() => {
    const dDate = add(cDate.value, { days: days.value });
    return getDateStamp(dDate);
});

const displayDate = computed(() => {
    const dDate = new Date(calcDate.value);

    return format(dDate, 'PPP', {
        locale: locFr
    });
});

const prestOfDay = computed(() => {
    return prestations.value[calcDate.value] ?? null;
});

</script>

<style scoped>

.locked {
    background-color: #eee !important;
}

/* .text-green {
    color: #0f0;
} */

</style>
