<template>
    <div class="pa-8">
        <v-text-field v-model="settings.apiUsername" outlined label="Utilisateur"/>
        <v-text-field
            v-model="settings.apiPassword"
            label="mot de passe"
            outlined
            :type="showPw ? 'text' : 'password'"
            :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPw = !showPw"
        />
        <v-btn v-if="!pinValid" @click="validatePin">Configurations avancées</v-btn>
        <div v-if="pinValid" style="display: contents;">
            <v-text-field v-model="settings.apiUrl" outlined label="URL de l'API"/>
            <v-text-field v-model="settings.apiDeviceKey" outlined label="Device Key"/>
            <v-text-field
                v-model="settings.pincode"
                outlined
                label="Code Pin"
                :type="showPin ? 'text' : 'password'"
                :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPin = !showPin"
            />
            <v-switch v-model="settings.gpstrack" label="Activer GPS."/>
            <v-switch v-model="settings.barcodeStop" label="Terminer prestation manuellement."/>
            <v-switch v-model="settings.autoUpload" label="Syncronisation automatique après cloturation de la prestation."/>
        </div>
    </div>
</template>

<script setup>
import { useAppStore } from '@/store/app';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const pinValid = ref(false);
const showPw = ref(false);
const showPin = ref(false);

const { settings } = storeToRefs(useAppStore());

function validatePin() {
    const pin = prompt('PIN');
    if (pin == settings.value.pincode) {
        pinValid.value = true;
    }
}

</script>
